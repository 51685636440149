import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { PureComponent } from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';
import { styled } from '@compiled/react';

import Lozenge from '@atlaskit/lozenge';
import type { StylesConfig } from '@atlaskit/select';
import { AsyncSelect, components } from '@atlaskit/select';
import AkSpinner from '@atlaskit/spinner/spinner';
import { N900, N30, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { LegacyContentIcon } from '@confluence/icons/entry-points/LegacyContentIcon';

export const EMPTY_PAGE_ID = 'no-page-selection';
export const EMPTY_SPACE_KEY = '';

const noop = () => {};

const i18n = defineMessages({
	loadingMessage: {
		id: 'page-selector.pages.loading',
		defaultMessage: 'Loading content',
		description:
			'The text inside the parent selector drop down telling user it is searching for content',
	},
	parent: {
		id: 'page-selector.parent.page',
		defaultMessage: 'Parent',
		description: 'The text header of content selector',
	},
	selectParent: {
		id: 'page-selector.select.parent.page',
		defaultMessage: 'Select a parent',
		description: 'Place holder message for content selector when there is empty parent',
	},
	searchError: {
		id: 'page-selector.search.error',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"Sorry, we're having trouble loading search results right now.",
		description:
			'An error message inside the page selector component when a user searches for a page and we are unable to perform the search',
	},
	noContentFound: {
		id: 'page-selector.no.page.found',
		defaultMessage: 'No content found',
		description:
			'The text inside the parent selector telling user that no content is found with the user query input',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PageName = styled.div({
	display: 'inline-block',
	flex: '1 0 auto',
	width: '75%',
	paddingBottom: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

const MAX_OPTION_DESCRIPTOR_WIDTH = 140;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageNameValueTruncated = styled.span({
	display: 'inline-block',
	verticalAlign: 'middle',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: `calc(100% - ${MAX_OPTION_DESCRIPTOR_WIDTH}px)`,
	paddingRight: token('space.050', '4px'), // no exact match for "5px",
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageLabel = styled.div({
	color: token('color.text', N800),
	minWidth: 0,
	font: token('font.heading.xxsmall'),
	marginBottom: token('space.100', '8px'), // no exact match for "10px",
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Option = styled.div({
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
	flexDirection: 'row',
	lineHeight: 1.2,
	paddingTop: token('space.025', '2px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.075', '6px'),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.div({
	display: 'flex',
	flex: '0 1 auto',
	marginRight: token('space.100', '8px'), // no exact match for "10px",
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerAfter = styled.div({
	display: 'flex',
});

export type PageType = {
	id: string;
	contentId: string;
	title: string;
	spaceKey: string;
	descriptor?: string;
	spaceIcon?: string;
	spaceOperations?: any[];
	pageOperations?: any[];
};

export type OptionType = {
	label: string;
	options: PageType[] | null;
	dataTestID?: string;
};

type PageSelectorProps = {
	intl: IntlShape;
	onChange: (page: PageType) => void;
	defaultPage?: PageType;
	filterOption?: ({ value }: { value: any }) => boolean;
	fireAnalyticsEvent: (event: any) => void;
	htmlFor: string;
	loadOptions: (searchTerm?: string) => Promise<any>;
	defaultOptions: OptionType[];
	useMenuPortalTarget?: boolean;
	isLoading: boolean;
	isDisabled?: boolean;
	onInputChange: (value: string) => void;
	error?: Error;
	selectStyleOverrides?: StylesConfig<OptionType>;
};

export const renderPageIcon = (title: string, type?: string) => {
	return (
		<Icon data-test-id="page-icon">
			<LegacyContentIcon label={title} type={type} size="small" />
		</Icon>
	);
};

export class PageSelectorComponent extends PureComponent<PageSelectorProps> {
	static defaultProps = {
		defaultPage: {},
		htmlFor: '',
		onChange: noop,
		useMenuPortalTarget: false,
		selectStyleOverrides: {},
	};

	static getDerivedStateFromProps(props, state) {
		if (
			!props.isLoading &&
			!(state.currentPage.id === EMPTY_PAGE_ID && isEqual(state.currentPage, props.defaultPage))
		) {
			return {
				...state,
				currentPage: props.defaultPage,
			};
		}

		return {
			...state,
		};
	}

	state = {
		currentPage: isEmpty(this.props.defaultPage) ? null : this.props.defaultPage,
		menuIsOpen: false,
	};

	componentDidUpdate() {
		const { currentPage } = this.state;
		// one unique scenario where we need to call onSelect with the current page selection:
		// After mount, once the pageselector default page query is finished loading + we have a nonempty default page
		// passed down then we select that default page in state. Parent needs to know that this happened
		// (onSelect will not be automatically called)
		if (
			currentPage &&
			!this.props.isLoading &&
			!(currentPage.id === EMPTY_PAGE_ID && isEqual(currentPage, this.props.defaultPage))
		) {
			this.onSelect(currentPage);
		}
	}

	updateMenuState = (menuStatus: boolean) => {
		this.setState({
			menuIsOpen: menuStatus,
		});
	};

	onSelect = (page: PageType) => {
		this.setState({
			currentPage: page,
		});

		this.props.onChange(page);
	};

	getOptionLabel = (opt) => opt.title;

	getOptionValue = (opt) => opt.id;

	optionLabel = ({ title, id, descriptor, type }, context) => {
		const Selection = ({ children, id, title, context, type }) => (
			<Option data-test-id="page-selector-option">
				{id !== EMPTY_PAGE_ID && context !== 'value' ? renderPageIcon(title, type) : null}
				{children}
			</Option>
		);
		return (
			<Selection id={id} title={title} context={context} type={type}>
				<PageName>
					{context === 'value' && descriptor ? ( // truncate page name to allow space for descriptor in form value
						<PageNameValueTruncated>{title}</PageNameValueTruncated>
					) : (
						title
					)}
					{descriptor ? (
						<Lozenge maxWidth={`${MAX_OPTION_DESCRIPTOR_WIDTH}px`} appearance="inprogress">
							{descriptor}
						</Lozenge>
					) : null}
				</PageName>
				{context === 'value' ? (
					<SpinnerAfter>
						{this.props.isLoading ? <AkSpinner size={12} testId="pageselector-loading" /> : null}
					</SpinnerAfter>
				) : null}
			</Selection>
		);
	};

	formatOptionLabel = (opt, { context }) => this.optionLabel(opt, context);

	noOptionsMessage = () =>
		this.props.intl.formatMessage(this.props.error ? i18n.searchError : i18n.noContentFound);

	getCustomInput = (inputProps) => {
		const propsWithAccessibility = {
			...inputProps,
			role: 'combobox',
			'aria-labelledby': 'page-selector-label',
			'aria-haspopup': 'listbox',
			'aria-expanded': this.state.menuIsOpen,
		};

		return <components.Input {...propsWithAccessibility} />;
	};

	loadingMessage = () => this.props.intl.formatMessage(i18n.loadingMessage);

	getHeader = () => this.props.intl.formatMessage(i18n.parent);

	getPlaceHolder = () => this.props.intl.formatMessage(i18n.selectParent);

	getStyles = (): StylesConfig<OptionType> => ({
		container: (css) => ({ ...css, width: '100%' }),
		dropdownIndicator: (css) => ({ ...css, paddingLeft: 0 }),
		menu: (css) => ({ ...css, width: '100%' }),
		input: (css) => {
			return {
				...css,
				width: '100%',
				marginLeft: 0,
				paddingLeft: '5px',
			};
		},
		option: (css, { isFocused }) => {
			const color = isFocused ? token('color.text', N900) : undefined;

			let backgroundColor;

			if (isFocused) backgroundColor = token('color.background.input.hovered', N30);

			return {
				...css,
				width: '100%',
				backgroundColor,
				color,
			};
		},
		singleValue: (css) => ({
			...css,
			width: '100%',
			display: 'flex',
			padding: '2px 8px 2px 0px', // offset default padding of Atlaskit https://product-fabric.atlassian.net/browse/A1-208
		}),
	});

	render() {
		const { currentPage } = this.state;
		const { useMenuPortalTarget, selectStyleOverrides } = this.props;

		const AsyncSelectStyles = this.getStyles();
		const asyncSelectStyles = isEmpty(selectStyleOverrides)
			? AsyncSelectStyles
			: { ...AsyncSelectStyles, ...selectStyleOverrides };

		// Necessary so the zIndex would work
		// https://atlaskit.atlassian.com/packages/core/select/example/select-in-modal-dialog
		const extraProps = useMenuPortalTarget ? { menuPortalTarget: document.body } : {};

		return (
			<label htmlFor={this.props.htmlFor}>
				<HeaderWrapper>
					<PageLabel id="page-selector-label">{this.getHeader()}</PageLabel>
				</HeaderWrapper>
				<AsyncSelect<OptionType>
					isDisabled={this.props.isDisabled || this.props.isLoading}
					placeholder={this.getPlaceHolder()}
					onChange={this.onSelect as any}
					onInputChange={this.props.onInputChange}
					// @ts-ignore
					value={currentPage}
					noOptionsMessage={this.noOptionsMessage}
					loadingMessage={this.loadingMessage}
					formatOptionLabel={this.formatOptionLabel}
					getOptionLabel={this.getOptionLabel}
					getOptionValue={this.getOptionValue}
					loadOptions={this.props.loadOptions}
					defaultOptions={this.props.defaultOptions}
					filterOption={this.props.filterOption}
					styles={asyncSelectStyles}
					isMulti={false}
					classNamePrefix="page-selector"
					id="page-selector"
					components={{
						Input: this.getCustomInput,
					}}
					onMenuOpen={() => this.updateMenuState(true)}
					onMenuClose={() => this.updateMenuState(false)}
					{...extraProps}
				/>
			</label>
		);
	}
}
